<div class='header'>
  <h1>INTRO</h1>
</div>
<div class='parents' *ngFor="let entry of indexEntries">
  <p class='index-letter'>#{{entry.title}} wie...</p>
  <div class='children' *ngFor="let element of entry.children">
    <h3>#{{element.title}}</h3>
    <p>{{element.description}}</p>
  </div>
</div>
