<div class="top-area">
  <div class="menu-toggle">
    <img [src]="menuIconSrc" *ngIf="!isNavigationVisible" (click)="toggleNavigation()"
    (mouseover)="menuIconSrc = '../../../assets/icons/Menu_Hover.svg'"
    (mouseout)="menuIconSrc = '../../../assets/icons/Menu.svg'">
    <img [src]="menuIconSrc" *ngIf="isNavigationVisible" (click)="toggleNavigation()"
    (mouseover)="menuIconSrc = '../../../assets/icons/Delete_Hover.svg'"
    (mouseout)="menuIconSrc = '../../../assets/icons/Delete.svg'">
  </div>
  <div class="baumgarten-img" >
    <img src="../../../assets/vector-images/Baumgarten_Schriftzug.svg">
  </div>
  <div title="https://www.instagram.com/bwiebaumgarten" class="instagram-link">
    <a href="https://www.instagram.com/bwiebaumgarten">
    <img src="../../../assets/icons/Instagram.svg"></a>
  </div>
</div>
<div class="side-navigation" [style.width.px]="navigationBarWidth">
  <a routerLink="intro" (click)="toggleNavigation()" >INTRO</a>
  <a routerLink="countdown" (click)="toggleNavigation()">COUNTDOWN</a>
</div>
