import { IndexParent } from './../../interfaces/IndexParent';
import { Component, OnInit } from '@angular/core';
import { IndexElement } from 'src/app/interfaces/IndexElement';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.sass']
})
export class IntroComponent implements OnInit {

  indexEntries: IndexParent[];

  constructor() { }

  ngOnInit(): void {
    this.indexEntries = [];
    this.indexEntries.push({title: 'A',
    children: [{title: 'Alter', description: 'Man ist nur so alt wie man sich fühlt. Klar, sehen wir auch so. Also fühlt euch besser mindestens volljährig und vergesst nicht das auch eurem Ausweis mitzuteilen. Wer unter 18 ist : Wir streamen Live auf jeden Balkon und in jeden Garten in Neuhausen! Indianerehrenwort.'}]});

    this.indexEntries.push({title: 'B',
        children: [{title: 'Bändel', description: 'Kein Bändel, kein Baumgarten! Unsere Regel: Kein Rausch beim ersten Date! Wer abends ohne Bändel vorbeikommt, für den heißt es daher: Wir haben heute leider kein Räuschle für dich. Also davor schon anbändeln, dann vorbeikommen und abgehn!'},
        {title: 'Brigitte', description: 'Ist der Name des Gewitters das über uns herein bricht, wenn die Sträucher in Mitleidenschaft gezogen werden. Oder sich Flaschen im Teich wiederfinden. Oder die Hecke flambiert. Oder, oder, oder... Macht ihr kein Scheiß bekommen wir kein Stress. Ist doch n fairer Deal, oder?'}]});
  }

}
