import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.sass']
})
export class CountdownComponent implements OnInit, OnDestroy {

  days: string;
  hours: string;
  minutes: string;
  seconds: string;

  private secondInMs = 1000;
  private minutesInMs = 60 * this.secondInMs;
  private hoursInMs = 60 * this.minutesInMs;
  private daysInMs = 24 * this.hoursInMs;

  timer;

  constructor() { }
  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    this.showRemaining();
    this.timer = setInterval(() => this.showRemaining(), 1000);
  }

  showRemaining(): void{
    const now = new Date();
    const endDate = new Date(2021, 7, 24, 8, 0, 0, 0);

    const distance = endDate.getTime() - now.getTime();
    this.seconds = this.integerToRoman(Math.floor((distance % this.minutesInMs) / this.secondInMs));
    this.minutes =  this.integerToRoman(Math.floor((distance % this.hoursInMs) / this.minutesInMs));
    this.hours = this.integerToRoman(Math.floor((distance % this.daysInMs) / this.hoursInMs));
    this.days = this.integerToRoman(Math.floor(distance / this.daysInMs));
  }

  private integerToRoman(original: number): string {
    if (original < 1 || original > 3999) {
      throw new Error('Error: Input integer limited to 1 through 3,999');
    }

    const numerals = [
      ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
      ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
      ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
      ['M', 'MM', 'MMM'], // 1000-3000
    ];

    const digits = Math.round(original).toString().split('');
    let position = (digits.length - 1);

    return digits.reduce((roman, digit) => {
      if (digit !== '0') {
        roman += numerals[position][parseInt(digit) - 1];
      }

      position -= 1;

      return roman;
    }, '');
  }
}
