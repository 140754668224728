import { CountdownComponent } from './components/countdown/countdown.component';
import { IntroComponent } from './components/intro/intro.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {path: 'intro', component: IntroComponent},
  {path: 'countdown', component: CountdownComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
