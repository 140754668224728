import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  menuIconSrc: string;

  navigationBarWidth: number;
  isNavigationVisible: boolean;

  constructor() {
    this.navigationBarWidth = 0;
   }

  ngOnInit(): void {
    this.menuIconSrc = '../../../assets/icons/Menu.svg';
  }

  toggleNavigation(): void{
    this.navigationBarWidth = 250;
    this.isNavigationVisible = !this.isNavigationVisible;

    if (this.isNavigationVisible){
      this.navigationBarWidth = 250;
      this.menuIconSrc = '../../../assets/icons/Delete.svg';
    } else {
      this.navigationBarWidth = 0;
      this.menuIconSrc = '../../../assets/icons/Menu.svg';
    }

  }

}
